<template>
    <div style="background: #F9F9F9">
        <s-header :name="'我的收藏'"></s-header>
        <ul>
            <li class="list" v-for="item in info" :key="item.id" @click="gotoDetail(item.product && item.product.id)">
                <img width="120" :src="item.product &&  item.product.picture" />
                <div style="flex: 1;margin-left: 10px">
                    <p style="margin-bottom: 40px">{{item.product && item.product.name}}</p>
                    <p style="display: flex;justify-content: space-between">
                        <span>￥{{ item.product && item.product.price }}</span>
                        <span style="color: #999">{{ item.create_at  }}</span>
                    </p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import sHeader from '@/components/SimpleHeader'
    import {useRouter} from 'vue-router'
    import {onMounted, ref} from 'vue'
    import {getFollow} from '@/service/good'
    export default {
        components: {
            sHeader
        },
        name: "FollowList",
        setup() {
            const router = useRouter()
            let info = ref([])
            onMounted(async () => {
                const {data} = await getFollow({page: 1})
                info.value = data;
            })
            function gotoDetail(id) {
                router.push({path: '/good/details', query: {id: id}})
            }
            return {
                info,
                gotoDetail
            }
        }
    }
</script>

<style scoped lang="less">
    .list{
        display: flex;
        align-items: center;
        background: #fff;
        padding: 10px 16px;
        border-bottom: 1px solid #eee;
        font-size: 16px;
    }
</style>
